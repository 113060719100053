import $ from 'jquery';

import Swiper, {Navigation, Autoplay,Pagination} from 'swiper';

import 'swiper/swiper.scss';  
//import 'swiper/modules/pagination/pagination.scss'; 
import 'swiper/modules/navigation/navigation.scss'; 

import './../../scss/blocks/block-news.scss';

$(document).ready(function() {
  swiperCard()
})

var init = false;
var swiper;
var slides = document.getElementsByClassName("news-slider-wrap");
var newsswiper = [];
function swiperCard() {
  if (window.innerWidth > 768) {
    if (!init) {
      init = true;

      for (var i = 0; i < slides.length; i++) {
        //Distribute(slides.item(i));
        newsswiper[i] = new Swiper(slides.item(i).getElementsByClassName('news-swiper').item(0), {
          modules: [Navigation],
          spaceBetween: 30,
          //loop: true,
          slidesPerView: 'auto',
          navigation: {
            nextEl: slides.item(i).getElementsByClassName('news-swiper-button-next').item(0),
            prevEl: slides.item(i).getElementsByClassName('news-swiper-button-prev').item(0),
          },
        });  
      }
    }
  } else if (init) {
    for (var i = 0; i < slides.length; i++) {
      //Distribute(slides.item(i));
      newsswiper[i].destroy();
       
    }
    init = false;
  }
}

window.addEventListener("resize", swiperCard);